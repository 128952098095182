import { isObject } from 'lodash';
import { Injectable } from '@angular/core';
import { AuthService } from '../../routes/user/auth.service';
import { CompaniesBackendService } from './backend/companies.backend.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { List } from 'immutable';
import { Company } from '../interfaces/company';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/company';
import { finalize, map, tap } from 'rxjs/operators';
import { SubscriptionPaymentsStoreService } from './subscription-payments-store.service';
import { LoaderService } from '../utils/loader.service';
import { encryptField } from '../utils/general';
import { TranslationsStoreService } from './translations-store.service';
import { EmailColumns } from '../interfaces/email-columns';
@Injectable({
  providedIn: 'root'
})
export class CompaniesStoreService {
  private companiesSubject = new BehaviorSubject(List([]));
  public readonly companies$: Observable<List<Company>>;// = this.companiesSubject.asObservable();
  public columns$: Observable<any[]>;

  constructor(private auth: AuthService,
    private customFields: CustomFieldsStoreService,
    private payments: SubscriptionPaymentsStoreService,
    private backend: CompaniesBackendService,
    private translationsStoreService: TranslationsStoreService,
    private loader: LoaderService,
  ) {
    this.auth.user$.subscribe(user => {
      if (!user || !user._id) {
        return;
      }
      this.load();
    });

    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('companies'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => col.name != "company.name" || user.isRoot());
        const fields = columns.toArray();

        const col = this.customFields.toColumnDef(defaults, {});

        const custom = this.customFields.toColumnDef(fields, {
          format: (col, field) => ({
            ...col,
            valueGetter: row => row.data.customFields ? row.data.customFields[field.name] : "",
            valueSetter: (row) => {
              let cf: any[string] = [];
              cf[row.colDef.colId] = row.newValue;
              row.data.customFields = { ...row.data.customFields, ...cf };
              return row;
            }
          })
        });
        return col.concat(custom);
      })
    );

    this.companies$ = combineLatest([this.payments.subscriptionPayments$, this.companiesSubject]).pipe(
      map(([payments, companies]) => companies.map((company: Company) => {
        const filtered = payments.filter(payment => payment.company && company && payment.company._id === company._id)
        if (filtered.count() > 0) {
          company.lastPayment = filtered.first()
          company.activePayments = filtered.filter(payment => payment.isActive())

        } else {
          company.activePayments = List([]);

        }

        return company;
      })
      )
    )
  }

  private remapCompany(company: any) {
    if (company.pptxTemplate && isObject(company.pptxTemplate)) {
      company.pptxTemplateObject = company.pptxTemplate;
      company.pptxTemplate = company.pptxTemplateObject._id;
    }
    return company;
  }

  load() {
    this.backend.list()
      .pipe(
        map(companies => companies.map(company => new Company(company))),
        map(companies => companies.map((company) => this.remapCompany(company))),
      )
      .subscribe((companies: Array<Company>) => {
        return this.companiesSubject.next(List(companies));
      })
  }

  syncCompany(id: string) {
    return this.backend.get(id)
      .pipe(
        map(company => new Company(company)),
        map((company) => this.remapCompany(company)),
        tap(company => {
          const companies = this.companiesSubject.getValue();
          const idx = companies.findIndex((c: Company) => c._id === id);
          if(idx > -1) {
            this.companiesSubject.next(companies.set(idx, company));
          } else {
            this.companiesSubject.next(companies.unshift(company));
          }
        }));
  }

  update(company: Company) {
    return this.backend.update(this.encrypt(this.customFields.includeUrl(company)))
      .pipe(
        map((ev) => this.remapCompany(ev)),
        tap(company => {
          const companies = this.companiesSubject.getValue();
          const idx = companies.findIndex((c: Company) => c._id === company._id);
          this.companiesSubject.next(companies.set(idx, company));
        }));
  }

  updateEmailSettings(company: Company) {
    return this.backend.updateEmailSettings(this.encrypt(this.customFields.includeUrl(company)))
      .pipe(
        map((ev) => this.remapCompany(ev)),
        tap(company => {
          const companies = this.companiesSubject.getValue();
          const idx = companies.findIndex((c: Company) => c._id === company._id);
          this.companiesSubject.next(companies.set(idx, company));
        }));
  }

  uploadPicture(files) {
    this.backend.uploadPicture(files)
      .subscribe(() => {
        this.load();
      });
  }

  uploadEula = (company, files) => {
    this.loader.add()
    return this.backend.uploadEula(company, files)
      .pipe(
        tap((data: Company) => {
          const companies = this.companiesSubject.getValue();
          const idx = companies.findIndex((c: Company) => c._id === data._id);
          this.companiesSubject.next(companies.set(idx, data));
          this.loader.remove()
        }),
        finalize(() => this.loader.remove())
      )
  }

  getCompanyById = (id: string) => this.companiesSubject.getValue().find(company => company._id === id);

  requestClose = () => this.backend.requestClose();
  requestDelete = () => this.backend.requestDelete();
  delete = (company: Company) => this.backend.delete(company);
  generateKeys = ( params ) => this.backend.generateKeys( params );
  requestReceipt = () => this.backend.requestReceipt();
  requestShare = () => this.backend.requestShare();
  requestUnshare = () => this.backend.requestUnshare();
  getCompanies = () => this.companiesSubject.getValue()
  generatePpt = () => this.backend.generatePpt();
  downloadPpt = () => this.backend.downloadPpt();
  emailColumns = () => this.backend.emailColumns();
  getScheduledReports = () => this.backend.getScheduledReports();
  addScheduledReport = (report: EmailColumns) => this.backend.addScheduledReport(report);
  updateScheduledReport = (report: EmailColumns) => this.backend.updateScheduledReport(report);
  deleteScheduledReport = (report: EmailColumns) => this.backend.deleteScheduledReport(report);
  duplicateScheduledReport = (report: EmailColumns) => this.backend.duplicateScheduledReport(report);
  generateQrCode = (companyId: string) => this.backend.generateQrCode(companyId);

  private encrypt = (company) => {
    if(this.auth.isRoot()){
      return {
        ...company,
        activeDirectory: {
          ...company.activeDirectory,
          clientId: company.activeDirectory && company.activeDirectory.clientId ? encryptField(company.activeDirectory.clientId) : null,
          authority: company.activeDirectory && company.activeDirectory.authority ? encryptField(company.activeDirectory.authority) : null,
          policy: company.activeDirectory && company.activeDirectory.policy ? encryptField(company.activeDirectory.policy) : null,
          tenant: company.activeDirectory && company.activeDirectory.tenant ? encryptField(company.activeDirectory.tenant) : null,
        },
        saml: {
          ...company.saml,
          entryPoint: company.saml && company.saml.entryPoint? encryptField(company.saml.entryPoint) : null,
          issuer: company.saml && company.saml.issuer? encryptField(company.saml.issuer) : null,
          cert: company.saml && company.saml.cert? encryptField(company.saml.cert) : null,
        },
        microsoftTeams: {
          ...company.microsoftTeams,
          incomingWebhook: company.microsoftTeams && company.microsoftTeams.incomingWebhook ? encryptField(company.microsoftTeams.incomingWebhook) : null,
        }
      };
    } else {
      return {
        ...company,
        microsoftTeams: {
          ...company.microsoftTeams,
          incomingWebhook: company.microsoftTeams && company.microsoftTeams.incomingWebhook ? encryptField(company.microsoftTeams.incomingWebhook) : null,
        }
      };
    }
  }

}
